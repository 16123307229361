import $ from 'jquery'
import 'select2'
import 'select2/dist/css/select2.css'

window.addEventListener('DOMContentLoaded', function() {
  return $('.select2').select2({
    placeholder: '選択してください',
    allowClear: true
  });
});

$(document).ready(function() {
  // #product-nested-fields-parentの変化(子要素の追加・削除)を監視。
  const options = {
    childList: true, //直接の子の変更を監視
  }

  //コールバック関数
  function callback () {
    // 追加された子要素にselect2を適用
    return $('.select2').select2({
      placeholder: '選択してください',
      allowClear: true
    });
  }

  //ターゲット要素をDOMで取得
  const target = document.querySelector('#product-nested-fields-parent');

  if (target !== null) {
    //インスタンス化
    const obs = new MutationObserver(callback);
    //ターゲット要素の監視を開始
    obs.observe(target, options);
  }
});
