import $ from "jquery";

$(function() {
  initEraRadio()
  eraRadioChecked()
  birthDayMonthChanged()
  birthDayYearChanged()
});

function initEraRadio() {
  const $showaEraRadio = $('#ShowaEra')
  const $heiseiEraRadio = $('#HeiseiEra')
  const hiddenClass= 'd-none'

  if ($showaEraRadio.prop("checked")) {
    $('#HeiseiEraYear').addClass(hiddenClass).prop('disabled', true)
    $('#ShowaEraYear').removeClass(hiddenClass).prop('disabled', false)
  } else if ($heiseiEraRadio.prop("checked")) {
    $('#ShowaEraYear').addClass(hiddenClass).prop('disabled', true)
    $('#HeiseiEraYear').removeClass(hiddenClass).prop('disabled', false)
  }
}

function resetBirthdayInput() {
  $('#EraBirthdayContainer select').val('')
}

function resetBirthdayDateOptions() {
  const firstOption = `<option value="">日</option>`
  $('#BirthDayDate').html(firstOption)
}

function birthDayMonthChanged() {
  $(document).on('change', '#BirthDayMonth', function() {
    const showaEraRadioChecked = $('#ShowaEra').prop("checked")
    const heiseiEraRadioChecked = $('#HeiseiEra').prop("checked")
    const eraName = showaEraRadioChecked ? 'ShowaEra' : heiseiEraRadioChecked ? 'HeiseiEra' : ''
    const eraYearVal = $(`#${eraName}Year`).val()
    const val = $('#BirthDayMonth').val()

    if(val && eraName && eraYearVal) {
      fetchDaysFromEraYear(eraName, eraYearVal, val)
    } else {
      resetBirthdayDateOptions()
    }
  })
}

function birthDayYearChanged() {
  $(document).on('change', '.EraYear', function() {
    $('#BirthDayMonth').trigger('change')
  })
}

function fetchDaysFromEraYear(eraName, eraYearVal, monthVal) {
  const url = '/contacts/personals/days_from_era_year_and_month?' + new URLSearchParams({
    eraName,
    eraYearVal,
    monthVal
  })

  fetch(url)
  .then(response => response.json())
  .then(data => {
    const firstOption = `<option value="">日</option>`
    $('#BirthDayDate').html(firstOption)
    data.forEach(i => {
      const option = `<option value="${i}">${i}</option>`
      $('#BirthDayDate').append(option)
    });
  });
}

function eraRadioChecked() {
  $(document).on('click', '#ShowaEra,#HeiseiEra', function() {
    initEraRadio()
    resetBirthdayInput()
    resetBirthdayDateOptions()
  })
}