import $ from "jquery";

export  default class ToggleElement {
  constructor() {}

  toggleDisabledInputWithRadioButton (el, elWrapper, elInput) {
    el.on('click', function () {
      elWrapper.toggleClass('display-none', $(this).val() === 'false')
      elInput.val('')
    })
  }

  handleInputEtc (elSelect, otherVal, elWrapperInput, elEtc) {
    elSelect.on('change', function () {
      elWrapperInput.toggleClass('display-none', $(this).val() !== otherVal.val())
      elEtc.val('')
    })
  }
}
