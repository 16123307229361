import $ from "jquery";
import toggleElement from "./toggleElement";

$(document).ready(function() {
  searchAddressUser()
  searchAddressUserHome()
  searchAddressUserWork()
  toggleDriverLicense()
  toggleContactInformations()
  handleContactRelationshipEtc()
  handleIndustoryEtc()
  handlePensionEtc()
  toggleEtcIncomeStatus()
  checkbox_disabled()
  parent_and_sibling_disabled()
  child_disabled()
  select_disabled()
  handle_has_income()
  own_work()
  foreign_peps()
})

function searchAddressUser () {
  $('#get-zipcode-user').on('click', function() {
    const zipcode = $('.user-zipcode-first').val() + $('.user-zipcode-last').val()
    $.ajax({
      url: 'https://zipcloud.ibsnet.co.jp/api/search?zipcode=' + zipcode,
      dataType : 'jsonp',
    }).done(function(data) {
      if(data.results) {
        $('.user-prefecture').val(data.results[0].address1)
        $('.user-city').val(data.results[0].address2)
        $('.user-town').val(data.results[0].address3)
        $('.user-prefecture-kana').val(data.results[0].kana1)
        $('.user-city-kana').val(data.results[0].kana2)
        $('.user-town-kana').val(data.results[0].kana3)
      } else {
        alert('存在しない郵便番号です。番号を確認して再検索してください。');
      }
    }).fail(function(data) {
      alert('通信に失敗しました');
    });
  })
}

function searchAddressUserHome () {
  $('#get-contact-zipcode').on('click', function() {
    const zipcode = $('.user-home-contact-zipcode-first').val() + $('.user-home-contact-zipcode-last').val()
    $.ajax({
      url: 'https://zipcloud.ibsnet.co.jp/api/search?zipcode=' + zipcode,
      dataType : 'jsonp',
    }).done(function(data) {
      if(data.results) {
        $('.user-home-contact-prefecture').val(data.results[0].address1)
        $('.user-home-contact-city').val(data.results[0].address2)
        $('.user-home-contact-town').val(data.results[0].address3)
      } else {
        alert('存在しない郵便番号です。番号を確認して再検索してください。');
      }
    }).fail(function(data) {
      alert('通信に失敗しました');
    });
  })
}

function searchAddressUserWork () {
  $('#get-user-work-zipcode').on('click', function() {
    const zipcode = $('.user-work-zipcode-first').val() + $('.user-work-zipcode-last').val()
    $.ajax({
      url: 'https://zipcloud.ibsnet.co.jp/api/search?zipcode=' + zipcode,
      dataType : 'jsonp',
    }).done(function(data) {
      if(data.results) {
        $('.user-work-prefecture').val(data.results[0].address1)
        $('.user-work-city').val(data.results[0].address2)
        $('.user-work-town').val(data.results[0].address3)
        $('.user-work-prefecture-kana').val(data.results[0].kana1)
        $('.user-work-city-kana').val(data.results[0].kana2)
        $('.user-work-town-kana').val(data.results[0].kana3)
      } else {
        alert('存在しない郵便番号です。番号を確認して再検索してください。');
      }
    }).fail(function(data) {
      alert('通信に失敗しました');
    });
  })
}

// 運転免許書のフォームの非表示可対応 radioボタンに対して
function toggleDriverLicense () {
  const el = $('.driver-license')
  const elWrapper = $('.wrapper-driver-license')
  const elInput = $('.driver-license-number')
  new toggleElement().toggleDisabledInputWithRadioButton(el, elWrapper, elInput)
}

// チェックボックスの非表示可対応 checkboxに対して
function checkbox_disabled () {
  // 初回読み込み時
  // 固定電話
  $(function() {
    if($('#not_have_landline_phone_checkbox').is(':checked') == true){
      $('.wrapper-checbox-tel').addClass('display-none');
      $('.house-tel').prop('disabled', true);
    }else {
      $('.wrapper-checbox-tel').removeClass('display-none');
      $('.house-tel').prop('disabled', false);
    }
  });
  // 携帯電話
  $(function() {
    if($('#not_have_mobile_phone_checkbox').is(':checked') == true){
      $('.wrapper-checbox-option-tel').addClass('display-none');
      $('.cellphone').prop('disabled', true);
    }else {
      $('.wrapper-checbox-option-tel').removeClass('display-none');
      $('.cellphone').prop('disabled', false);
    }
  });
  // 固定電話
  $('#not_have_landline_phone_checkbox').on('change', function () {
    if ($(this).prop('checked')) {
      $('.wrapper-checbox-tel').addClass('display-none');
      $('.house-tel').prop('disabled', true);
    }else {
      $('.wrapper-checbox-tel').removeClass('display-none');
      $('.house-tel').prop('disabled', false);
    }
  })
  // 携帯電話
  $('#not_have_mobile_phone_checkbox').on('change', function () {
    if ($(this).prop('checked')) {
      $('.wrapper-checbox-option-tel').addClass('display-none');
      $('.cellphone').prop('disabled', true);
    }else {
      $('.wrapper-checbox-option-tel').removeClass('display-none');
      $('.cellphone').prop('disabled', false);
    }
  })
}

// 子供のラジオボタンで「あり」が選択された場合、子供の合計人数の入力フォームを表示
function child_disabled () {
  $(function() {
    if($('.has-child-true').is(':checked')) {
      $('.child-number').removeClass('display-none');
    }else {
      $('.child-number').addClass('display-none');
    }
  });
  $('.has-child').on('click', function (){
    if($(this).val() == 'true') {
      $('.child-number').removeClass('display-none');
    }else {
      $('.child-number').addClass('display-none');
    }
  });
}

// 親兄弟他のラジオボタンで「あり」が選択された場合、親兄弟の合計人数の入力フォームを表示
function parent_and_sibling_disabled () {
  $(function() {
    if($('.has-parent-or-sibling-true').is(':checked')) {
      $('.parent-and-sibling-number').removeClass('display-none');
    }else {
      $('.parent-and-sibling-number').addClass('display-none');
    }
  });
  $('.has-parent-or-sibling').on('click', function (){
    if($(this).val() == 'true') {
      $('.parent-and-sibling-number').removeClass('display-none');
    }else {
      $('.parent-and-sibling-number').addClass('display-none');
    }
  });
}

// 生計主セレクトボックスにて「本人」が選択された場合の「生計主フォームをすべて取り消す」
function select_disabled () {
  // 初回読み込み時
  $(function() {
    const own_owner = $('[name="update_user_form[owner]"] option:selected').text();
    if (own_owner == "本人") {
      $('.own_owner').addClass('display-none');
      $('.own-owner-disable').prop('disabled', true);
    }
    else {
      $('.own_owner').removeClass('display-none');
      $('.own-owner-disable').prop('disabled', false);
    }
  });
  // セレクトに対してのアクション時
  $('.sl-owner-own').on('change', function () {
    const own_owner = $('[name="update_user_form[owner]"] option:selected').text();
    if (own_owner == "本人") {
      $('.own_owner').addClass('display-none');
      $('.own-owner-disable').prop('disabled', true);
    }
    else {
      $('.own_owner').removeClass('display-none');
      $('.own-owner-disable').prop('disabled', false);
    }
  });
}


// ご職業で有職者を選択した際に収入なしの選択不可とする
// また、ご職業で年金を選択した際、「年金・不動産賃収入について」の各フォームに必須のアイコンを付与する
function own_work () {
  // 初回読み込み時
  $(function() {
    const user_work = $('[name="update_user_form[work]"] option:selected').text();
    if (user_work == "有職者") {
      $('.has_income_status_disable').prop('disabled', true);
    }
    if (user_work == "年金"){
      $('.switch-required-by-pension').removeClass('input-not-required')
      $('.switch-required-by-pension').addClass('input-required')
    } else {
      $('.switch-required-by-pension').removeClass('input-required')
      $('.switch-required-by-pension').addClass('input-not-required')
    }
  });
  // セレクトに対してのアクション時
  $('.own_work').on('change', function () {
    const user_work = $('[name="update_user_form[work]"] option:selected').text();
    if (user_work == "有職者") {
      $('.has_income_status_disable').prop('disabled', true);
    }
    else {
      $('.has_income_status_disable').prop('disabled', false);
    }
    if (user_work == "年金"){
      $('.switch-required-by-pension').removeClass('input-not-required')
      $('.switch-required-by-pension').addClass('input-required')
    } else {
      $('.switch-required-by-pension').removeClass('input-required')
      $('.switch-required-by-pension').addClass('input-not-required')
    }
  });
}

// 「お勤め先からの収入の有無」で「無」を選択した場合、お勤め先についてのフォームを非表示
function handle_has_income () {
  $(function(){
    // 初回読み込み時
    const path = location.pathname;
    if (path == "/contacts/personals/edit" || path == "/contacts/personals" || path == "/contacts/personals/new"){
      if($('.has_imcome_status_false').is(':checked') == true){
        $('.wrapper-user-work-informations').addClass('display-none');
      }
    }
  });
  $('.has_income_status').on('click', function(){
    if($(this).val() == 'false'){
      $('.wrapper-user-work-informations').addClass('display-none');
      $('.user-work-disable').prop('disabled', true);
    } else {
      $('.wrapper-user-work-informations').removeClass('display-none');
      $('.user-work-disable').prop('disabled', false);
    }
  });
}

function foreign_peps () {
  // 初回入力時
  $(function() {
    if ($('.foreign_peps_disabled').prop('checked')) {
      $('.foreign_peps').addClass('display-none');
      $('.foreign_peps_national').prop('disabled', true);
    }
  });
  //該当あり/なしのばあいそれぞれの振る舞い
  $('.foreign_peps_disabled').on('change', function () {
    if ($(this).prop('checked')) {
      $('.foreign_peps').addClass('display-none');
      $('.foreign_peps_national').prop('disabled', true);
    }
  });
  $('.foreign_peps_abled').on('change', function () {
    if ($(this).prop('checked')) {
      $('.foreign_peps').removeClass('display-none');
      $('.foreign_peps_national').prop('disabled', false);
    }
  });
}

// 一人住まいまたは単身赴任で「はい」が選択されている場合のみ、ご実家等の連絡先についてのフォームを表示する
function toggleContactInformations () {
  $(function() {
    if($('.living-alone-status-false').is(':checked') == true){
      $('.wrapper-contact-informations').addClass('display-none');
      $('.contact-disable').prop('disabled', true);
    } else {
      $('.wrapper-contact-informations').removeClass('display-none');
      $('.contact-disable').prop('disabled', false);
    }
  });
  $('.living-alone-status').on('click', function(){
    if($(this).val() == 'true'){
      $('.wrapper-contact-informations').removeClass('display-none');
      $('.contact-disable').prop('disabled', false);
    } else {
      $('.wrapper-contact-informations').addClass('display-none');
      $('.contact-disable').prop('disabled', true);
    }
  });
}

// その他収入の有無で「あり」が選択されている場合のみ、その他収入についての入力フォームを表示する
function toggleEtcIncomeStatus () {
  $('.etc-income-status').on('click', function(){
    if($(this).val() == 'true'){
      $('.wrapper-etc-income-status').toggleClass('d-none');
    } else {
      $('.wrapper-etc-income-status').addClass('d-none');
    }
  });
}

function handleContactRelationshipEtc () {
  const elSelect = $('.sl-contact-relationship')
  const otherVal = $('.contact-relationship-other')
  const elWrapperInput = $('.wrapper-contact-relationship')
  const elEtc = $('.contact-relationship-etc')
  new toggleElement().handleInputEtc(elSelect, otherVal, elWrapperInput, elEtc)
}

function handleIndustoryEtc () {
  const elSelect = $('.sl-industory')
  const otherVal = $('.industory-other')
  const elWrapperInput = $('.wrapper-industory')
  const elEtc = $('.industory-etc')
  new toggleElement().handleInputEtc(elSelect, otherVal, elWrapperInput, elEtc)
}

function handlePensionEtc () {
  const elSelect = $('.sl-pension')
  const otherVal = $('.pension-other')
  const elWrapperInput = $('.wrapper-pension')
  const elEtc = $('.pension-etc')
  new toggleElement().handleInputEtc(elSelect, otherVal, elWrapperInput, elEtc)
}
