import $ from "jquery";
export class Repayment {

  toggleDisableLink () {
    $('.checkbox-policy').on('click', function () {
      $('.btn-rules').toggleClass('disable-link', $('.checkbox-policy:checked').length !== $('.checkbox-policy').length)
    })
  }
  // ボーナス併用払いを利用しない場合、ボーナス併用払いについての入力エリアを非表示に
  toggleDisableInput () {
    // 初回読み込み時
    $(function() {
      if($('.bonus-payment-false').is(':checked') == true){
        $('.bonus-payment-hidden').addClass('display-none');
        $('.bonus-payment-disable').prop('disabled', true);
      } else {
        $('.bonus-payment-hidden').removeClass('display-none');
        $('.bonus-payment-disable').prop('disabled', false);
      }
    });
    $('.bonus-payment').on('click', function(){
      if($(this).val() == 'true'){
        $('.bonus-payment-hidden').removeClass('display-none');
        $('.bonus-payment-disable').prop('disabled', false);
      } else {
        $('.bonus-payment-hidden').addClass('display-none');
        $('.bonus-payment-disable').prop('disabled', true);
      }
    });
  }
}
