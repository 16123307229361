import $ from "jquery";
import {Repayment} from "./repayment";
import AutoNumeric from 'autonumeric'

$(document).ready(function() {
  setTimeout(function() {
    return $('.flash_messages').slideUp(1000);
  }, 5000);

  productLimit()

  customRadio()
  formatNumber()
  maxValueInput()
  addLinkIntoTable()
  formatCurrencyInput()
  formatNumberHasCommas()
  toggleDetailDisplay()
  new Repayment().toggleDisableInput()
  new Repayment().toggleDisableLink()

  // #product-nested-fields-parentの変化(子要素の追加・削除)を監視。
  const options = {
    childList: true, //直接の子の変更を監視
  }
  //MutationObserverインスタンスを作成
  const observer = new MutationObserver(function(){
    // 商品の表示制限の関数を呼び出し
    productLimit()
    // 数値入力フォーム関係の関数を呼び出し
    formatNumber()
    formatCurrencyInput()
    formatNumberHasCommas()
    toggleDetailDisplay()
  });

  //ターゲット要素をDOMで取得
  const target = document.querySelector('#product-nested-fields-parent')
  if (target !== null) {
    //ターゲット要素の監視を開始
    observer.observe(target, options);
  }
});

function productLimit () {
  const product_fields = $('#product-nested-fields-parent .nested-fields:visible');
  // 商品が3つ以上の場合は追加ボタンを非表示
  if (product_fields.length >= 3){
    $('.product-add-btn').hide();
  } else{
    $('.product-add-btn').show();
  }
  // 商品が1つの場合は削除ボタンを非表示
  if (product_fields.length <= 1){
    $('.product-remove-btn').hide();
  } else{
    $('.product-remove-btn').show();
  }
}

// 役務の有無によって、別紙詳細の有無の入力エリアの表示を切り替え
function toggleDetailDisplay () {
  const input_status_id = 'provision-status'
  const fields = $('.has-attachment-detail-hidden');
  // 役務の有無の入力エリアのidと、別紙詳細の有無の入力フォームのclassにそれぞれ同じ名前を付け、対応付けさせる。
  for(var i=0; i<=fields.length-1; i++){
    $('.provision-status')[i].setAttribute("id", input_status_id+i);
    fields[i].classList.add(input_status_id+i);
  }
  // 初期表示時の処理
  for(var i=0; i<=fields.length-1; i++){
    if($(`#provision-status${i} input.provision-status-true`).is(':checked') == true){
      $(`.provision-status${i}`).removeClass('display-none');
    } else {
      $(`.provision-status${i}`).addClass('display-none');
    }
  }
  // クリックされた場合の処理
  $(`.provision-status input`).on('click', function(){
    for(var i=0; i<=fields.length-1; i++){
      if(this.value == 'true'){
        $(`.${this.parentNode.id}`).removeClass('display-none');
      } else {
        $(`.${this.parentNode.id}`).addClass('display-none');
      }
    }
  })
}

function formatNumber () {
  $('.number-input').on('keyup', function () {
    const numberVal = $(this).val().replace(/[^0-9]+/g, '')
    $(this).val(numberVal)
  })
}

function formatCurrencyInput () {
  $('.currency-input').on('keyup', function () {
    const numberVal = $(this).val().replace(/[^0-9,]+/g, '')
    $(this).val(numberVal)
  })
}

function formatNumberHasCommas () {
  const autoNumericOptions = {
    maximumValue: '99999999',
    digitGroupSeparator: ',',
    decimalPlaces: 0,
    currencySymbol: '',
    modifyValueOnWheel: false,
    watchExternalChanges: true
  }
  const $domElements = $('.number-commas');
  try {
    for (const element of $domElements.toArray()) {
      const elId = '#' + element.id
      if (AutoNumeric.isManagedByAutoNumeric(elId) === false) {
        new AutoNumeric.multiple(elId, autoNumericOptions)
      }
    }
    $domElements.on('input', function () {
      var anElement = AutoNumeric.getAutoNumericElement(this);
      if (anElement && this.value.length > 0 && anElement.getNumericString().length === 0) {
        anElement.set(this.value);
      }
    });
  } catch (_) {}
}

function maxValueInput () {
  $('.max-input').change(function() {
    var max = parseInt($(this).attr('max'));
    if ($(this).val() > max) {
      $(this).val(max);
    }
  });
}

function customRadio() {
  $(document).on('click mousedown keydown keyup', '.CustomRadio', function(e) {
    let radioButton = $(e.target);

    if(('keydown' === e.type  && 32 === e.keyCode) || 'mousedown' === e.type){
      radioButton.data('originally_checked', radioButton.prop('checked'));
    }
    if(('keyup' === e.type && 32 === e.keyCode) || 'click' === e.type){
      if(radioButton.data('originally_checked')){
        radioButton.prop('checked', false).removeAttr('checked');
      } else {
        radioButton.prop('checked', true).attr('checked', 'checked');
      }
      radioButton.removeData('originally_checked').trigger('done_changing');
    }
  });
}

function addLinkIntoTable() {
  $('.table-link').on('click', 'tbody tr', function(e) {
    if (e.target.className.indexOf('skip-redirect') === -1 && $(this).data('href'))
        window.location.href = $(this).data('href')
  })
}
