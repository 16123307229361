$(function () {
  $('input').not('.no-next').on('keyup', function () {
    var max = $(this).attr('maxlength');
    var current = $(this).val().length;
    if (current >= max) {
      var nextIndex = $('input').index(this) + 1;
      $('input')[nextIndex].focus();
    }
  })
})
