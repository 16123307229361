import $ from "jquery";
import toggleElement from "./toggleElement";

$(document).ready(function() {
  togglePossessionOcCard()
  toggleApplyCard()
})

function togglePossessionOcCard () {
  const el = $('.has-possession-oc-card')
  const elWrapper = $('.wrapper-has-possession-oc-card')
  const elInput = $('.possession-oc-card-input')
  new toggleElement().toggleDisabledInputWithRadioButton(el, elWrapper, elInput)
}

function toggleApplyCard () {
  const el = $('.has-apply-card')
  const elWrapper = $('.wrapper-has-apply-card')
  const elInput = $('.has-apply-card-input')
  new toggleElement().toggleDisabledInputWithRadioButton(el, elWrapper, elInput)
}